 // Helper: Convert 24-hour time to 12-hour AM/PM format
 // Convert 24-hour time to 12-hour time
export const convertTo12HourFormat = (timeStr) => {
    const [hour, minute] = timeStr.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minute.toString().padStart(2, '0')} ${period}`;
  };
  
export const parseHourToDate = (hourStr) => {
    const today = new Date(); // Define today's date
    const [time, period] = hourStr.split(' ');
    const [hour, minute] = time.split(':').map(Number);
    const adjustedHour = period ? (period.toLowerCase() === 'pm' ? hour + 12 : hour) : hour; // Handle AM/PM
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), adjustedHour, minute || 0);
};

export const generateTimeSlots = () => {
  const slots = [];
  const start = new Date();
  start.setHours(10, 0, 0, 0); // Start at 9:00 AM
  const end = new Date();
  end.setHours(19, 0, 0, 0); // End at 9:00 PM

  while (start < end) {
      // Format the time as "HH:MM AM/PM"
      const hours = start.getHours();
      const minutes = start.getMinutes().toString().padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedTime = `${(hours % 12) || 12}:${minutes} ${ampm}`;
      slots.push(formattedTime);

      // Move to the next 60-minute slot
      start.setMinutes(start.getMinutes() + 60);
  }

  return slots;
}

// This function will return 10 days starting from tommorrow, they will be shown in the calendar.
export const getDatesFromTomorrow = (daysCount = 30) => {
  const dates = [];
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  for (let i = 0; i < daysCount; i++) {
    const date = new Date(tomorrow);
    date.setDate(tomorrow.getDate() + i);
    dates.push(date); // Store `Date` objects, not strings
  }

  return dates;
};

export const filterAvailableTimes = ({ booked = [], targetDate }) => {
  if (!targetDate) {
    console.error("Target date is required.");
    return [];
  }

  const allTimes = generateTimeSlots(); // Array of all time slots
  
  // Format targetDate to a local date string 'YYYY-MM-DD'
  const targetDateString = targetDate.toLocaleDateString('en-CA'); // 'YYYY-MM-DD' in local timezone

  // Check if the targetDate is a Monday
  const isMonday = targetDate.getDay() === 1;

  // Collect times for bookings that match the target date
  const bookedTimes = booked.reduce((times, { date, time }) => {
    const bookedDateString = date.split("T")[0]; // Extract the date part before "T"
    if (bookedDateString === targetDateString) {
      times.push(time); // Add the time to the array
    }
    return times;
  }, []);

  // Filter out the booked times and exclude 10:00 to 13:00 if it's Monday
  const availableTimes = allTimes.filter((time) => {
    const [hour] = time.split(":").map(Number); // Extract the hour part
    if (isMonday && hour >= 10 && hour < 13) {
      return false; // Exclude times between 10:00 and 12:59 on Mondays
    }
    return !bookedTimes.includes(time); // Exclude booked times
  });

  return availableTimes;
};
