import { parseHourToDate } from "./time";

// Get the next occurrence of a specific day
export const getNextDayOccurrence = (day, hours) => {
    if (typeof day !== 'string') {
        console.error("Expected 'day' to be a string, received:", typeof day);
        return null;
    }

    const today = new Date();
    const todayDayOfWeek = today.getDay();
    const daysOfWeek = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];

    const targetDayIndex = daysOfWeek.indexOf(day.toLowerCase().trim());

    if (targetDayIndex === -1) {
        console.error(`Invalid day: ${day}`);
        return null;
    }

    const parseHourToDate = (hourStr) => {
        if (typeof hourStr !== 'string') {
            console.error("Expected 'hourStr' to be a string, received:", typeof hourStr);
            return new Date();  // fallback to current date
        }

        const [time, period] = hourStr.split(' ');
        const [hour, minute] = time.split(':').map(Number);
        const adjustedHour = period ? (period.toLowerCase() === 'pm' ? hour + 12 : hour) : hour;
        return new Date(today.getFullYear(), today.getMonth(), today.getDate(), adjustedHour, minute || 0);
    };

    const hourStrings = hours.split(',').map(h => h.trim());
    const parsedTimes = hourStrings.map(parseHourToDate);
    const allTimesPast = parsedTimes.every(parsedTime => parsedTime < today);

    let daysUntil = targetDayIndex - todayDayOfWeek;
    if (daysUntil < 0 || (daysUntil === 0 && allTimesPast)) {
        daysUntil += 7;
    }

    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysUntil);
    return nextDate;
};


// Parse date strings into Date objects
export const parseDateString = (dateStr) => {
const [year, month, day] = dateStr.split('-').map(Number);
return new Date(year, month - 1, day);
};

export const processBookings = ({ usedBookings }) => {
  const dateTimePairs = [];

  usedBookings.forEach((booking) => {
    const { Date, Time } = booking.fields;

    if (Date && Time) {
      dateTimePairs.push({ date: Date, time: Time });
    }
  });

  return dateTimePairs;
};
