import React, { useState, useEffect } from 'react';
import CustomDatePicker from './CustomDatePicker'; 
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Button, Container } from 'react-bootstrap';
import { getUnitInfo, getCurrentBookings, addAppointment, addAppointmentToAirtable, sendDataToMakeWebhook } from '../api/calendarApi';
import { handleData } from '../utils/fetchData';
import { processBookings } from '../utils/date';
import { getDatesFromTomorrow, filterAvailableTimes } from '../utils/time';

const VisitScheduler = ({}) => {
   
  // https://errandboy.io/calendar/?type=1&baseId=appkM4dyOFE9Ku58w&tableId=tblw2pyeXF3Sel71M&unitId=519&language=FR&phone=+18192009431 
  // An example of the url we need to build
  // THis is used for the prod environment
  // https://prod.errandboy.io/calendar/?type=1&baseId=appkM4dyOFE9Ku58w&tableId=tblw2pyeXF3Sel71M&unitId=519&language=FR&phone=+18192009431

  const airtableApiKey = 'patkVd7aEFUVkRGkN.7c4003ac2bff6d1de358127808272152344275c50c14e358ce8ccf4cf889b3cc'

  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState()
  const [selectedIndex, setSelectedIndex] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formData, setFormData] = useState({ name: '', email: ''});
  const [unitId, setUnitId] = useState(null);
  const [baseId, setBaseId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [language, setLanguage] = useState("FR");
  const [phone_number, setPhone_number] = useState(null)
  const [airtableBaseId, setAirtableBaseId] = useState('')
  const [airtableTableId, setAirtableTableId] = useState('')
  const [webhookUrl, setWebhookUrl] = useState()
  const [type, setType] = useState('1') // This param will be 0 if it's fixed and 1 if it's variable timeslots
  const [usedDates, setUsedDates] = useState([])
  const [timeIsChecked, setTimeIsChecked] = useState(false)
  const [currentSelectedTime, setCurrentSelectedTime] = useState(''); // Local state for selected time
  const [unitIdNotProvided, setUnitIdNotProvided] = useState(false)//To be removed with the other version, this is spaghetti code

  const [explanation, setExplanation] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fetchedType = params.get('type');
    const fetchedBaseId = params.get('baseId');
    const fetchedTableId = params.get('tableId');
    const fetchedUnitId = params.get('unitId');
    const language = params.get('language');
    const phone_number = params.get('phone');
  
    // Set phone number to an empty string if not provided
    setPhone_number(phone_number || '');
  
    // Set unitId to 519 if not provided
    setUnitId(fetchedUnitId || 519);

    if(!fetchedUnitId){
      setUnitIdNotProvided(true)
    }
  
    // Always set these, even if unitId is missing
    setType(fetchedType || '');
    setBaseId(fetchedBaseId || '');
    setTableId(fetchedTableId || '');
    setLanguage(language || 'FR');
  }, []);
  
  // Ensure `fetchDates` only runs after necessary state variables are set
  useEffect(() => {
    if (type && baseId && tableId && unitId) {
      fetchDates();
    }
  }, [type, baseId, tableId, unitId]);

  const fetchDates = async () => {
    setAvailableDates([]);
    setAvailableTimes([]);
    
    if(type ==='0'){
      let data = [];
      try {
        data = await getUnitInfo({ unitId, baseId, tableId, airtableApiKey });
      } catch (error) {
        // Handle or display error
      }
    
      const formattedData = handleData({data, getSubmitInfo})
      const aDates = formattedData.map(item => item.date);
      const aTimes = formattedData.map(item => item.hours);
      setAvailableDates(aDates);
      setAvailableTimes(aTimes);
      if (aDates.length > 0) {
        setSelectedDate(aDates[0]);
      }

      // Setting the explanation for the calendar if it's fixed
      if(language === 'EN') {
        const str = "Please select a day and time from the available slots.\nIf none suit you, check back on Monday for updated options."
        const formattedExplanation = str.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ));
        setExplanation(formattedExplanation)
      } else if (language === 'SP'){
        const str = "Por favor, elija un día y una hora entre los horarios disponibles.\nSi ninguno le conviene, vuelva el lunes para ver nuevas opciones."
        const formattedExplanation = str.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ));
        setExplanation(formattedExplanation)
      }else{
        const str = "Veuillez choisir un jour et une heure parmi les créneaux disponibles.\nSi aucun ne vous convient, revenez lundi pour des options mises à jour.";

        const formattedExplanation = str.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ));
        setExplanation(formattedExplanation)
      }

    }else if (type ==='1'){
      /*
        TODO 
        We need to add that if the persone wants to visit on a time where julia is already there at that time, 
        then it should be available up to 10 people.
      */
      let data = [];
      data = await getUnitInfo({ unitId, baseId, tableId, airtableApiKey });

      if(Object.keys(data).length > 0){ 
        const usedBookings = await getCurrentBookings({ baseId, tableId, airtableApiKey })
        const combinedArray = processBookings({usedBookings});
      
        setUsedDates(combinedArray)

        if (data.records[0]["fields"]["WebhookUrl"]){
          setWebhookUrl(data.records[0]["fields"]["WebhookUrl"]);
          setAirtableTableId(data.records[0]["fields"]["TableId"]);
        }
        const aDates = getDatesFromTomorrow(10)
        setAvailableDates(aDates)
        setSelectedDate(aDates[0])
      }
      // Setting the explanation for the calendar if it's variable
      if(language === 'EN') {
        const str = "Please select a day and time from the available slots.\nCurrently, no additional options are available beyond those shown."
        const formattedExplanation = str.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ));
        setExplanation(formattedExplanation)
      } else if (language === 'SP'){
        const str = "Por favor, elija un día y una hora entre los horarios disponibles.\nActualmente, no hay más opciones disponibles además de las que se muestran."
        const formattedExplanation = str.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ));
        setExplanation(formattedExplanation)
      }else{
        const str = "Veuillez choisir un jour et une heure parmi les créneaux disponibles.\nActuellement, aucune autre option n'est disponible au-delà de celles affichées."
        const formattedExplanation = str.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ));
        setExplanation(formattedExplanation)
      }
    }
  };

  useEffect(() => {
    fetchDates();
  }, [airtableApiKey, baseId, tableId, unitId]);
  
  const getSubmitInfo = (baseId, tableId, webhookUrl) => {
    setAirtableBaseId(baseId)
    setAirtableTableId(tableId)
    setWebhookUrl(webhookUrl)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setTimeIsChecked(false)
    setSelectedDate(date);
    setCurrentSelectedTime('')

    if(type ==='0'){
      for (var i=0; i < availableDates.length; i++){
        if(date.getTime() === availableDates[i].getTime()){
          const availableTimesTemp = availableTimes[i]
          setSelectedTime(availableTimesTemp);
          handleTimeChange(availableTimesTemp[selectedIndex], selectedIndex)     
        }
      }   
    }else{
      // This section is used to filter the already selected times.
      const xx = filterAvailableTimes({booked: usedDates, targetDate: date})
      setAvailableTimes(xx)
    }
  };

  const handleTimeChange = (timePicked) => {
    setTimeIsChecked(true)
    setSelectedDateTime(timePicked)
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!timeIsChecked) {
      // Prevent submission if the time is not checked
      alert("Veuillez choisir une heure et date avant de soumettre.");
      return;
    }
  
    const nom = formData.name;
    const email = formData.email;
    const phoneSub = !formData.phone ? phone_number : formData.phone; // Handle if the phone is provided
    const id = !formData.unitId ? unitId.toString() : formData.unitId.toString(); 
  
    const appointment = {
      nom,
      email,
      selectedDate,
      selectedDateTime,
      phoneSub,
      id,
    };
  
    addAppointment({ appointment, baseId, airtableTableId, airtableApiKey, webhookUrl });
    setFormSubmitted(true);
  };
  
  
  // This container will show if the person has submitted the form
  if (formSubmitted) {
    return <Container className="mt-5 booking-form">
        <h2 className="text-center mb-4">
          {language === 'EN' ? 'Thank you! Looking forward to seeing you during the visit' : 
            language === 'FR' ? 'Merci! Au plaisir de vous rencontrer lors de la visite' : 
            language === 'SP' ? '¡Gracias! Esperamos verte durante la visita.': "Merci! Au plaisir de vous rencontrer lors de la visite" }
        </h2>
    </Container>
  }

  return (
    loading ? 
    (<>
      <div>
      <Container className="mt-5 booking-form">
        <h2 className="text-center mb-4">
          {language === 'EN' ? <strong>Make an appointment</strong> : 
            language === 'FR' ? <strong>Prendre un rendez-vous</strong> : 
            language === 'SP' ? <strong>Hacer una cita</strong>: "" }
        </h2>
        <p className='mb-3 text-center'>{explanation}</p>
        <hr className='mb-3' />
        <Form onSubmit={handleSubmit}>

          <Form.Group controlId="formName">
            <Form.Label>
              {language === 'EN' ? 'Name' : 
              language === 'FR' ? 'Nom' : 
              language === 'SP' ? 'Appellido': ""}
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder={language === 'EN' ? 'Enter your name' : 
              language === 'FR' ? 'Entrer votre nom' : 
              language === 'SP' ? 'Introduce tu appellido': ""}
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>
            {language === 'EN' ? 'Email' : 
              language === 'FR' ? 'Courriel' : 
              language === 'SP' ? 'Correo electronico': ""}  
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={language === 'EN' ? 'Enter your email' : 
                language === 'FR' ? 'Entrer votre courriel' : 
                language === 'SP' ? 'Introduce tu correo electrónico': ""}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          
          {!phone_number && (
          <Form.Group controlId="formPhone">
            <Form.Label>
              {language === 'EN' ? 'Phone number' : 
                language === 'FR' ? 'Numéro de téléphone' : 
                language === 'SP' ? 'Numero de telefono' : ""}  
            </Form.Label>
            <Form.Control
              type="text"
              name="phone"
              placeholder={language === 'EN' ? 'Enter your phone number' : 
                language === 'FR' ? 'Entrer votre numéro de téléphone' : 
                language === 'SP' ? 'Introduce tu número de telefono' : ""}
              value={formData.phone}
              onChange={handleChange}
              required
            />
            </Form.Group>
            )}

          {unitIdNotProvided && (
          <Form.Group controlId="formPhone">
            <Form.Label>
              {language === 'EN' ? 'Unit_Id' : 
                language === 'FR' ? "Numéro d'identification de l'unité" : 
                language === 'SP' ? 'Numero de identificacion' : ""}  
            </Form.Label>
            <Form.Control
              type="text"
              name="unitId"
              placeholder={language === 'EN' ? 'Enter the id number' : 
                language === 'FR' ? "Entrer le # d'identification" : 
                language === 'SP' ? 'Introduce tu número de identificacion' : ""}
              value={formData.unitId}
              onChange={handleChange}
              required
            />
            </Form.Group>
            )}

            <CustomDatePicker 
              type={type}
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              availableDates={availableDates}
              availableTimes={availableTimes}
              setSelectedTime={handleTimeChange} 
              currentSelectedTime={currentSelectedTime}
              setCurrentSelectedTime={setCurrentSelectedTime}
              language={language}
            />
          
          <Button variant="primary" type="submit" className="w-100">
            {language === 'EN' ? 'Reserve' : 
            language === 'FR' ? 'Réserver' : 
            language === 'SP' ? 'Reservar': ""}  
          </Button>
        </Form>
      </Container>
    </div></>): <h2>Loading...</h2>
  );
};

export default VisitScheduler;
