import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const TimePicker = ({ availableTime, handleTimeChange, selectedTime, language }) => {

  return (
    <Form.Group controlId="formTime" style={{ marginTop: '5px' }}>
      <Form.Label>
        {language === 'EN'
          ? 'Select a time'
          : language === 'FR'
          ? 'Sélectionner une heure'
          : language === 'SP'
          ? 'Selecciona una hora'
          : ''}
      </Form.Label>
      <Form.Control
        as="select"
        value={selectedTime}
        onChange={(e) => handleTimeChange(e.target.value)}
      >
        {/* Default option */}
        <option value="" disabled>
          {language === 'EN'
            ? 'Select a time'
            : language === 'FR'
            ? 'Sélectionner une heure'
            : language === 'SP'
            ? 'Selecciona una hora'
            : ''}
        </option>
        {/* Dynamic options */}
        {availableTime.length > 0 ? (
          availableTime.map((time, index) => (
            <option key={index} value={time}>
              {time}
            </option>
          ))
        ) : (
          <option disabled>
            {language === 'EN'
              ? 'No available times'
              : language === 'FR'
              ? 'Aucune heure disponible'
              : language === 'SP'
              ? 'No hay horas disponibles'
              : ''}
          </option>
        )}
      </Form.Control>
    </Form.Group>
  );
};

export default TimePicker;
