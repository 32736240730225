import { convertTo12HourFormat } from '../utils/time';
import { parseDateString } from '../utils/date';

export const handleData = ({ data, getSubmitInfo }) => {

  const daysOfWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ensure we're only comparing dates, not times
  const startDate = new Date(today);
  startDate.setDate(startDate.getDate()); // Start from tomorrow

  const formattedData = [];

  function parseDateString(dateString) {
    const parts = dateString.split('/'); // Split the input string by "/"
    
    if (parts.length === 3) {
      const [month, day, year] = parts;
      
      // Construct the date string in "YYYY-MM-DD" format
      const dateStr = `${year}-${month}-${day}`;
      
      // Create a Date object but ensure it's in Eastern Time (America/New_York)
      const easternTimeDate = new Date(`${dateStr}T00:00:00`);
      
      // Convert the Date object to Eastern Time
      const easternTimeString = easternTimeDate.toLocaleString('en-US', { timeZone: 'America/New_York' });

      // Parse the localized string back to a Date object
      const easternTimeDateCorrected = new Date(easternTimeString);

      return easternTimeDateCorrected;
    }

    // If the input is not in the expected format, return it as a regular Date
    return new Date(dateString); 
  }

  
  // Loop through the next 9 days
  for (let i = 0; i < 9; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + i); // Increment day by day
    const currentDayName = currentDate.toLocaleDateString('fr-FR', { weekday: 'long' }).toLowerCase();

    data.records.forEach((record) => {
      const { WebhookUrl, TableId, BaseId } = record.fields;
      getSubmitInfo(BaseId, TableId, WebhookUrl);

      Object.keys(record.fields).forEach((fieldKey) => {
        if (fieldKey.startsWith('Option')) {
          const [firstPart, hours] = record.fields[fieldKey].split(';');

          if (daysOfWeek.includes(firstPart.toLowerCase().trim())) {
            // Handle days of the week
            if (currentDayName === firstPart.toLowerCase().trim()) {
              formattedData.push({
                date: new Date(currentDate),
                hours: hours.split(',').map((hour) => convertTo12HourFormat(hour.trim())),
                id: record.id,
              });
            }
          } else {
            // Handle specific dates
            const specificDate = parseDateString(firstPart);

            specificDate.setHours(0, 0, 0, 0); // Normalize time for date comparison
            if (
              specificDate.toDateString() === currentDate.toDateString() &&
              !formattedData.some((entry) => entry.date.toDateString() === specificDate.toDateString())
            ) {
              formattedData.push({
                date: new Date(specificDate),
                hours: hours.split(',').map((hour) => convertTo12HourFormat(hour.trim())),
                id: record.id,
              });

              console.log("Formatted date", formattedData)
            }
          }
        }
      });
    });

    // Stop processing once we have 10 entries
    if (formattedData.length >= 10) break;
  }

  return formattedData;
};